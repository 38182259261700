import React from 'react'
import {BsCheckLg} from 'react-icons/bs'
import { Tab } from '@headlessui/react'
import { useState } from 'react';
import './pricing.css'

function Pricing() {
    const[checked,setChecked]=useState(false)

    function handleChange(){
        setChecked(!checked)
    }

  return (
    <div className='pricing' id="pricing">
        <div className='pricing-container container'>
            <div className='pricing-header pb-5 text-center'>
                <h2 className='common-header-2'>Try <span className='highlighted-text'>GreenScan</span> for free</h2>
            </div>
            <div className='row text-center pb-5'>
                <div className='col-md-4 mb-2'>
                    <p className='pricing-top'><BsCheckLg style={{color:"blue"}}/> Free 15 day trial</p>
                </div>
                <div className='col-md-4 mb-2'>
                    <p className='pricing-top'><BsCheckLg style={{color:"blue"}}/> Unlimited Team Members</p>
                </div>
                <div className='col-md-4 mb-2'>
                    <p className='pricing-top'><BsCheckLg style={{color:"blue"}}/> Cancel Anytime</p>
                </div>
            </div>
            
            <div className='row mb-5'>
                <div className='col-lg-4 mb-4 pricing-tab-outer text-center px-5 flex'>
                    <div className='pricing-tab py-5 px-3'>
                        <h2 className='pricing-header'>Starter</h2>
                        <h1 className='pricing-cost'>&euro;39</h1>
                        <p className='common-description'>per user, per month</p>
                        <button className='pricing-button py-2'>Start my 15-day Trial</button>
                        <hr className='pricing-line'/>
                        <ul className='pricing-list common-description'>
                            <li className='py-1'>2500 automations/day</li>
                            <li className='py-1'>Climate Intelligence platform access</li>
                            <li className='py-1'>Standard API Access</li>
                            <li className='py-1'>Industry Based Insights</li>
                            <li className='py-1'>7-Day Forecast</li>
                        </ul>
                    </div> 
                </div>
                <div className='col-lg-4 mb-4 pricing-tab-outer text-center px-5 flex'>
                <div className='pricing-tab-2 py-5 px-3'>
                        <h2 className='pricing-header'>Business</h2>
                        <h1 className='pricing-cost'>&euro;199</h1>
                        <p className='common-description' style={{color:"white"}}>per user, per month</p>
                        <button className='pricing-button-2 py-2'>Start my 15-day Trial</button>
                        <p className='common-description' style={{color:"white"}}>Everything in starter + more</p>
                        <hr className='pricing-line'/>
                        <ul className='pricing-list pricing-list-2 common-description' style={{color:"white"}}>
                            <li className='py-1'>15000 automations/day</li>
                            <li className='py-1'>Advanced API Access</li>
                            <li className='py-1'>Standard Asset Portfolio Builder</li>
                            <li className='py-1'>14-Day Forecast</li>
                            <li className='py-1'>72 Hour Historical Weather Data</li>
                            <li className='py-1'>5 Seats / 5 Guests per Seat</li>
                            <li>24/7 VIP Support</li>
                        </ul>
                    </div> 
                </div>
                <div className='col-lg-4 mb-4 pricing-tab-outer text-center px-5'>
                    
                    <div className='pricing-tab py-5 px-3'>
                        <h2 className='pricing-header'>Enterprise</h2>
                        <ul className='pricing-list common-description'>
                            <li className='py-1'>Everything in Business + more</li>
                            <li className='py-1'>Custom Automation / Location</li>
                            <li className='py-1'>Custom Seats / 10 guests per seat</li>
                            <li className='py-1'>GreenScan data Layers</li>
                            <li className='py-1'>GreenScan Workflow Builder</li>
                        </ul>
                        <button className='pricing-button py-2'>Start my 15-day Trial</button>
                        <hr className='pricing-line'/>
                        <ul className='pricing-list common-description'>
                            <li className='py-1'>GreenScan Asset Portfolio Builder</li>
                            <li className='py-1'>GSAirQ Asset Optimizer</li>
                            <li className='py-1'>GS Flood Asset Optimizer</li>
                            <li className='py-1'>GS Wind Asset Optimizer</li>
                            <li className='py-1'>SAML-based SSO</li>
                            <li className='py-1'>Custo SLAs</li>
                            <li className='py-1'>24/7 VIP Support</li>
                        </ul>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Pricing