import React from 'react'
import LandingScreen from '../components/Landing Page/LandingScreen'
import LandingAbout from '../components/Landing Page/LandingAbout'
import LandingCA1 from '../components/Landing Page/LandingCA1'
import LandingFeatures from '../components/Landing Page/LandingFeatures'
import LandingSolution from '../components/Landing Page/LandingSolution'
// import Pricing from '../components/Landing Page/Pricing'
// import LandingProducts from '../components/Landing Page/LandingProducts'
import LandingTeam from '../components/Landing Page/LandingTeam'
import LandingCA2 from '../components/Landing Page/Landing CA2'
import JuaDemo from '../components/JUA/JuaDemo'

function Landing() {
    return (
        <>
            <LandingScreen />
            <LandingAbout />
            <LandingCA1/> 
            <LandingSolution />  
            <LandingFeatures />         
            <JuaDemo />
            {/* <Pricing /> */}
            <LandingTeam />
            <LandingCA2 />
        </>
    )
}

export default Landing
