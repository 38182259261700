import React from 'react'
import {Link} from 'react-router-dom'
import {Link as LinkR} from 'react-scroll'
import {Button} from '../../Button'
// import img1 from '../../../images/LandingPage/DemoExample.png'
import icon1 from '../../../images/LandingPage/i1.svg'
import icon2 from '../../../images/LandingPage/i2.svg'
import icon3 from '../../../images/LandingPage/i3.svg'
import './landingSolution.css'

function index() {

    const featuresData = [
        {
            heading:"Faster renewable energy transition",
            description:"Rich Library of weather parameters to cover any condition in any location.",
            icon:icon1
        },
        {
            heading:"Extreme weather event prediction",
            description:"Rich Library of weather parameters to cover any condition in any location.",
            icon:icon2
        },
        {
            heading:"Forecast of air quality issues",
            description:"Rich Library of weather parameters to cover any condition in any location.",
            icon:icon3
        },
    ]

    return (
        <>
            <div className="landingSolution" id="landing-solution">
                <div className="landingSolution-container container">
                    <div className="row ">
                        <div className="col-lg-6 landingSolution-content">
                        <h3 className='common-subheader2'>Product</h3>
                            <h2 className="common-header">GreenScan's <br/> Climate Solution</h2>
                            <p className="common-description">JUA leverages artificial Intelligence (AI) and Machine learning (ML) which has become an integral part of the renewable and climate-based decision-making process. We are using data-driven approach with an aim to make significant contributions towards our climate.</p>
                            <div className="landingSolution-buttons">
                                <LinkR to="jua-demo" smooth={true} duration={500}><Button buttonStyle="btn--primary" buttonClasses="margin-5"> Start Now </Button></LinkR>
                                <Link to="/form"><Button buttonStyle="btn--outline"> Get Demo </Button></Link>
                            </div>
                        </div>

                        {/* <div className="col-md-6 landingSolution-content">
                            <img src={img1}  alt="demo example" className="solution-demo-image"/>
                        </div> */}
                    </div>

                    <div className="row">
                        {featuresData.map((val,ind)=>{
                            return(
                                <div className="col-lg-4 solution-feature-points" key={ind}>
                                    <img src={val.icon} alt="waves" className="solution-feature-point-image"></img>
                                    <div className="solution-features-points-text">
                                        <h2 className="common-subheader">{val.heading}</h2>
                                        <p className="common-description">{val.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
        </>
    )
}

export default index
