import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Navbar from '../src/components/Navbar'
import Footer from '../src/components/Footer'
import Landing from './pages/Landing';
// import Products from './pages/Products';
// import About from './pages/About';
// import Jua from './pages/Jua';
// import Tufan from './pages/Tufan';
import DemoForm from './pages/DemoForm';
import Software from './pages/Software';
import GoToTop from './pages/GoToTop'
import formOk from './components/Form/formOk'
import Tufan from './pages/Tufan';


function App() {
  return (
    <>
      <Router>
        <GoToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/23823jadfsdflkf023923214342" exact component={Software} />
          <Route path="/GSWind" exact component={Tufan} />
          {/* <Route path="/products" exact component={Products} />
          <Route path="/about" exact component={About} />
          <Route path="/jua" exact component={Jua} />
          <Route path="/tufan" exact component={Tufan} /> */}
          <Route path="/form" exact component={DemoForm} />
          <Route path="/formOk" exact component={formOk} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
