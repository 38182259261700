import React from 'react'
import { Link } from 'react-router-dom'
import { Link as LinkR } from 'react-scroll'
import { Button } from '../Button'
import img1 from '../../images/global/Logo_GreenScan-05.svg'
import icon2 from '../../images/global/linkedin-logotype.png'
import {MdLocationOn, MdPhone} from 'react-icons/md'
import './footer.css'

function index() {

  const style = {
    color: "black",
    fontSize: "16px",
    fontWeight: "700"
  }

  return (
    <footer className="footer">
      <div className="footer__addr">
        <Link to="/"><img src={img1} className="footer-logo-image" alt="GreenScan Logo"></img></Link>
      </div>

      <ul className="footer__nav">
        <li className="nav__item mb-5">
          <h2 className="nav__title" style={style}>Quick Links</h2>

          <ul className="nav__ul">
            <li>
              <LinkR to="landing-solution" spy={true} smooth={true} offset={-100} duration={500} >
                Product
              </LinkR>
            </li>

            <li>
            <LinkR to="landing-features" spy={true} smooth={true} offset={-100} duration={500} >
                Features
              </LinkR>
            </li>
            <li>
            <LinkR to="jua-demo" spy={true} smooth={true} offset={-100} duration={500} >
                Demo
              </LinkR>
            </li>
          </ul>
        </li>

        <li className="nav__item mb-5">
          <h2 className="nav__title" style={style}>Company</h2>

          <ul className="nav__ul">
            <li>
              <LinkR to="landing-about" spy={true} smooth={true} offset={-100} duration={500} >
                About
              </LinkR>
            </li>
            <li>
              <LinkR to="landing-team" spy={true} smooth={true} offset={-100} duration={500} >
                Team
              </LinkR>
            </li>
          </ul>
        </li>

        <li className="nav__item mb-5">
          <h2 className="nav__title" style={style}>Get in Touch</h2>

          <ul className="nav__ul">
            {/* <li>
              <Link to='/form'>Contact</Link>
            </li> */}
            <li className='d-flex'>
              <div className='marr-2'>
                <MdLocationOn size="20px" /> 
              </div>
              <div>
                <Link>Vaart ZZ 1d, 9401GE, Assen, The Netherlands</Link>
              </div>             
            </li>
            <li className='d-flex'>
              <div className='marr-2'>
                <MdPhone size="20px" /> 
              </div>
              <div>
                <Link>+31618364277</Link>
              </div>             
            </li>

            {/* <li>
              <a href="mailto:info@gs.io">Email Us</a>
            </li> */}

          </ul>
        </li>

        <li className="nav__item mb-5">
          <Link to="/form"><Button buttonStyle="btn__primary"> Demo </Button></Link>
        </li>
      </ul>

      <div className="legal">
        <div>
        <p>&copy; 2022 Greenscan. All rights reserved.</p>
        </div>
        

        <div className="legal__links">
          <p>Follow Us on: <a href="https://www.linkedin.com/company/greenscan-io/" target="__blank" rel="noreferrer"><img src={icon2} className="icons-logo" alt="linkedIn" /></a></p>
        </div>
      </div>
    </footer>
  )
}

export default index
