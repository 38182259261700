import React from 'react'
import './form.css'

function formOk() {
    return (
        <>
            <div className='formOk-component'>
                <div className='form-success'>
                    <div className='text-center'>
                        <h1 className='formOk-heading'>Thank You!</h1>
                        <p>Our team will shortly get in touch with you for further steps. Thank you for contacting us.</p>
                        
                    </div>
                </div>

            </div>
        </>
    )
}

export default formOk
