import React from 'react'
import img1 from '../../../images/Demo/image1.jpg'
import img2 from '../../../images/Demo/image2.png'
import './tufanFeatures.css'

function index() {
    return (
        <>
            <div className="tufan-features">
                <div className="tufan-features-container">
                    <div className="tufan-feature-1 pt-5 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 tufan-features-content">
                                    <div>
                                        <h2 className="common-header">Feature#1</h2>
                                        <p className="common-subheader">Navigate operational challenges.<br/>Gain Visibility on asset risks.</p>
                                        <p className="common-description">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quiacon sequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 tufan-features-content">
                                    <div>
                                        <img src={img1} className="tufan-features-image" alt="feature 1"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tufan-feature-2 pt-5 pb-5">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-6 tufan-features-content">
                                    <div className="large-screen">
                                        <img src={img2} className="tufan-features-image" alt="feature 1"></img>
                                    </div>
                                </div>

                                <div className="col-md-6 tufan-features-content">
                                    <div>
                                        <h2 className="common-header">Feature#2</h2>
                                        <p className="common-subheader">Navigate operational challenges.<br/>Gain Visibility on asset risks.</p>
                                        <p className="common-description">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quiacon sequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 tufan-features-content">
                                    <div className="small-screen">
                                        <img src={img1} className="tufan-features-image" alt="feature 1"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index
