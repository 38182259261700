import React,{useState} from 'react'
import axios from 'axios'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import img1 from '../../../images/TufanImages/agb_000.jpg'
import img2 from '../../../images/TufanImages/agb_001.jpg'
import img3 from '../../../images/TufanImages/agb_002.jpg'
import img4 from '../../../images/TufanImages/agb_016.jpg'
import img5 from '../../../images/TufanImages/agb_017.jpg'
import img6 from '../../../images/TufanImages/agb_019.jpg'
import img7 from '../../../images/TufanImages/agb_020.jpg'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend
  } from "recharts";
import './tufanDemo.css'

function TufanDemo(){

    const [data,setData] = useState([
        {
            img:img1,
            real_speed:40,
            predicted_speed:null
        },
        {
            img:img2,
            real_speed:40,
            predicted_speed:null
        },
        {
            img:img3,
            real_speed:40,
            predicted_speed:null
        },
        {
            img:img4,
            real_speed:43,
            predicted_speed:null
        },
        {
            img:img5,
            real_speed:44,
            predicted_speed:null
        },
        {
            img:img6,
            real_speed:44,
            predicted_speed:null
        },
        {
            img:img7,
            real_speed:57,
            predicted_speed:null
        },
    ])




    const [image,setImage] = useState(data[0].img);
    const [prev, setPrev] = useState(0);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const marks=[0,1,2,3,4,5,6]

    // const hiddenFileInput = useRef(null);
  
    // const handleClick = () => {
    //     hiddenFileInput.current.click();
    // };

    // const handleChange = event =>{
    //     const fileUploaded = event.target.files[0];
    //     setImage(URL.createObjectURL(fileUploaded));
    //     postImage(fileUploaded)
    // }

    const handleChange2 = async value =>{
        
        console.log(value)
        setImage(data[value].img)
        if(prev<=value){
            for(let i=prev; i<=value;i++){
                setLoading(true)
                console.log(i)
                handleImage(i)
            }
            setLoading(false)
            setPrev(value)
        }
        else{
            for(let i=value;i<=prev;i++){
                let newArr=[...data];
                newArr[i].predicted_speed = null;
            }
            setPrev(value)
        }

        
        handleImage(value)
    }

    // const postImage = async (imageData)=>{
    //     setResult(null);
    //     setLoading(true);
    //     const formData = new FormData();
    //     formData.append('file',imageData);
    //     let url = "https://tufanspeed.herokuapp.com/Image/predict_speed";
    //     let res = await axios.post(url,formData);
    //     let result2 = res.data;
    //     setResult(result2)
    //     setLoading(false);
    // }

    // const style={
    //     backgroundImage:'url('+image+')',
    //     backgroundSize:'cover',
    //     backgroundPosition:'center',
    //     width:'100%',
    //     height:'100%'
    // }

    const handleImage = async(index) =>{
        setResult(null);
        setLoading(true);
        setImage(data[index].img)
        const response = await fetch(data[index].img)
        const blob = await response.blob()
        const file = new File([blob],'dot.png',{type:'image/png'})

        const formData = new FormData();
        formData.append('file',file);
        let url = "https://tufanspeed.herokuapp.com/Image/predict_speed";
        let res = await axios.post(url,formData);
        let result2 = res.data;
        data[index].predicted_speed = result2.prediction;
        // console.log("fetched Data "+index)
        setData(data);
        // console.log("set Data")
        setResult(result2)
        setLoading(false);
    }

    function renderResult(){
        if(result)
        return result.prediction
        else if(loading===false)
        return "--"
        else
        return (
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        )
    }





    // const dotStyle={stroke:"#82ca9d"}
    // function renderImage(){
    //     if(image){
    //         return (
    //             <div className="row" style={style}>
    //                 <div className="col-md-6 offset-md-6 tufan-demo-speed">
    //                     <div className="tufan-demo-speed-card">
    //                         <p className="tufan-demo-speed-header">Predicted Wind Speed</p>
    //                         <div className="tufan-demo-speed-predictions">
    //                             <img className="tufan-demo-result-image" src="https://img.icons8.com/ios-filled/50/ffffff/wind--v1.png" alt="Wind icon"/>
    //                             <div className="tufan-demo-result-text">
    //                                 <p className="predicted-speed">{renderResult()} knots</p>
    //                                 <p className="storm-category">Severe Storm</p>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }
    //     else{
    //         return(
    //             <div>
    //                 <p className="common-description">Please input an image</p>
    //             </div>
    //         )
    //     }
    // }

    return(
        <>
            <div className="tufan-demo" id="tufan-demo">
                <div className="tufan-demo-container container">
                    <div className="tufan-demo-header text-center">
                        <h1 className="common-header">GS Wind Platform Demo</h1>
                        <p className="common-subheader mb-5">Move the slider to see the results.</p>
                    </div>

                    {/* <div className="tufan-demo-component mb-5">
                        <div className="tufan-demo-component-inner">
                            <div className="row">

                                <div className="col-md-4 tufan-demo-options">
                                    <div className="tufan-demo-input">
                                        <p className="subheader2">Please upload an image of the storm to see the results:</p>
                                        <button className="btn btn-primary tufan-demo-button" onClick={handleClick}>Upload an image</button>
                                        <input 
                                            type="file"
                                            name="file"
                                            ref={hiddenFileInput}
                                            onChange={handleChange}
                                            style={{display:'none'}}
                                        />
                                    </div>

                                    <p className="common-description tufan-demo-description mt-5" >
                                        <span style={{fontWeight:"bold"}}>Result Description:</span><br/>
                                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed qui magni dolores eo qui ratione voluptatem sequi nesciunt. Neque porr quisquam est, qui dolorem.Neque porro quisquam est,qui dolorem.
                                    </p>
                                </div>


                                <div className="col-md-8 tufan-demo-canvas p-1">
                                    <div className="tufan-demo-canvas-inner">
                                        <div className="text-center">
                                            <p>Tropical Depression ABCD</p>
                                        </div>

                                        <div className="tufan-demo-results">
                                            <div className="tufan-demo-image">
                                                {renderImage()}
                                            </div>

                                            
                                        </div>
                                        

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div> */}


                    {/* <div className="tufan-demo-component">
                        <div className="tufan-demo-component-inner">
                            <div className="row">


                                <div className="col-md-4 tufan-demo-options">
                                    <div className="tufan-demo-input ">
                                        <p className="subheader2">Select an image of the storm to see the results:</p>
                                        <div className="tufan-demo-input-2">
                                            <div className="row">
                                                {data.map((val,ind)=>{
                                                    return(
                                                        <div className="col-6 mb-2" key={ind} onClick={() => handleImage(ind)}>
                                                            <img src={val.img} style={{width:"90%"}}></img>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            
                                        </div>
                                        
                                    </div>

                                    <p className="common-description tufan-demo-description mt-5" >
                                        <span style={{fontWeight:"bold"}}>Result Description:</span><br/>
                                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed qui magni dolores eo qui ratione voluptatem sequi nesciunt. Neque porr quisquam est, qui dolorem.Neque porro quisquam est,qui dolorem.
                                    </p>
                                </div>


                                <div className="col-md-8 tufan-demo-canvas p-1">
                                    <div className="tufan-demo-canvas-inner">
                                        <div className="text-center">
                                            <p>Tropical Depression ABCD</p>
                                        </div>

                                        <div className="tufan-demo-results row">
                                            <div className="col-md-6 mb-5 tufan-selected-image-container">
                                                <img src={image} className="tufan-selected-image"></img>
                                            </div>
                                            <div className="col-md-6 tufan-demo-result-container-2">
                                                <div className="tufan-demo-speed-card mb-5">
                                                    <p className="tufan-demo-speed-header">Predicted Wind Speed</p>
                                                    <div className="tufan-demo-speed-predictions">
                                                        <img className="tufan-demo-result-image" src="https://img.icons8.com/ios-filled/50/ffffff/wind--v1.png" alt="Wind icon"/>
                                                        <div className="tufan-demo-result-text">
                                                            <p className="predicted-speed">{renderResult()} knots</p>
                                                            <p className="storm-category">Severe Storm</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{height:"200px"}} className="mb-5">
                        <ResponsiveContainer >
                            <LineChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 0,
                                bottom: 10
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36}/>
                            <Line
                                type="monotone"
                                dataKey="real_speed"
                                stroke="green"
                                activeDot={{ r: 8 }}
                            />
                            <Line type="monotone" dataKey="predicted_speed" stroke="blue" dot={{ stroke: 'blue', strokeWidth: 1, r: 4,strokeDasharray:''}} />
                            </LineChart>
                        </ResponsiveContainer>
                        </div>


                                            </div>

                                            
                                        </div>
                                        

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div> */}

<div className="tufan-demo-component">
                        <div className="tufan-demo-component-inner">
                            <div className="row">


                                <div className="col-md-6 tufan-demo-options">
                                    <div className="tufan-demo-input-3 ">
                                    <div className="text-center">
                                    <p className="subheader2">Move the slider to see the results:</p>
                                        </div>
                                    <div className=" mb-5 tufan-selected-image-container">
                                        <img src={image} className="tufan-selected-image" alt="Storm"></img>
                                    </div>
                                        
                                    </div>
                                    <div>
                                        <Slider 
                                            min={0}
                                            max={6}
                                            marks={marks}
                                            onChange={handleChange2}
                                            />
                                    </div>
                                    

                                    {/* <p className="common-description tufan-demo-description mt-5" >
                                        <span style={{fontWeight:"bold"}}>Result Description:</span><br/>
                                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed qui magni dolores eo qui ratione voluptatem sequi nesciunt. Neque porr quisquam est, qui dolorem.Neque porro quisquam est,qui dolorem.
                                    </p> */}
                                </div>


                                <div className="col-md-6 tufan-demo-canvas p-1">
                                    <div className="tufan-demo-canvas-inner">
                                        <div className="text-center">
                                            <p>Results</p>
                                        </div>

                                        <div className="tufan-demo-results row">
                                           
                                            <div className=" tufan-demo-result-container-2">
                                                <div className="tufan-demo-speed-card mb-5">
                                                    <p className="tufan-demo-speed-header">Predicted Wind Speed</p>
                                                    <div className="tufan-demo-speed-predictions">
                                                        <img className="tufan-demo-result-image" src="https://img.icons8.com/ios-filled/50/ffffff/wind--v1.png" alt="Wind icon"/>
                                                        <div className="tufan-demo-result-text">
                                                            <p className="predicted-speed">{renderResult()} knots</p>
                                                            <p className="storm-category">Severe Storm</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{height:"200px"}} className="mb-5">
                        <ResponsiveContainer >
                            <LineChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 0,
                                bottom: 10
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36}/>
                            <Line
                                type="monotone"
                                dataKey="real_speed"
                                stroke="green"
                                activeDot={{ r: 8 }}
                            />
                            <Line type="monotone" dataKey="predicted_speed" stroke="blue" dot={{ stroke: 'blue', strokeWidth: 1, r: 4,strokeDasharray:''}} />
                            </LineChart>
                        </ResponsiveContainer>
                        </div>


                                            </div>

                                            
                                        </div>
                                        

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                

               
            </div>
        </>
    )
}

export default TufanDemo