import React from 'react'
import img1 from '../../../images/Software/hero-image.webp'
import './hero.css'

function index() {
  return (
    <div className='software-hero'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 software-hero-content'>
                    <h2 className='common-header mb-3'>Better operational decision-making with weather intelligence</h2>
                    <p className='software-hero-description mb-3'>Welcome to the all-in-one weather dashboard: Get the right information, generate invaluable insights, and drive actions.</p>
                    <div>
                        <button className=' software-hero-button'>Get Started</button>
                    </div>
                </div>
                <div className='col-lg-6 p-5 software-hero-image'>
                    <img src={img1} style={{width:"100%"}}></img>
                </div>
            </div>
        </div>
    </div>
  )
}

export default index