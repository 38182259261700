import React from 'react'
import {Link} from 'react-router-dom'
import './landingCA2.css'

function index() {
    return (
        <>
        <div className="landingCA2">
            <div className="landingCA2-container container">
                <h1 className="common-header">Get Started today</h1>
                <Link to="/form"><button className=" btn btn-primary callAction2-button"> Get Demo </button></Link>
            </div>
        </div>
           
        </>
    )
}

export default index
