import React from 'react'
import TufanLanding from '../components/Tufan/TufanLanding'
import TufanFeatures from '../components/Tufan/TufanFeatures'
import TufanDemo from '../components/Tufan/TufanDemo'

function Tufan() {
    return (
        <>
            <TufanLanding />
            <TufanFeatures />
            <TufanDemo />
        </>
    )
}

export default Tufan
