import React from 'react'
import { useState } from 'react'
import img1 from '../../../images/LandingPage/GSdemo.png'
import './landingFeatures.css'

function LandingFeatures() {

    const [index, setIndex] = useState(0)

    const featuresData = [
        {
            heading:"Advanced Deep Learning Innovation",
            description:"We trained some 60,000 infrared images of solar PVs with cutting-edge deep learning computer vision models to learn about 12 classes to identify anomalies such as dust,vegetation, etc.",
        },
        {
            heading:"Domain Centric Models",
            description:"For this multi-class problem, we experimented with various pretrained imagent models and use the best performing model on our solar anomaly dataset.",
        },
        {
            heading:"MLOPs Powered SaaS Platform",
            description:"Our AI SaaS platform is powered by end-to-end integrated MLOPs framework which allows customers to seamlessly onboard the platform and service their requests. We intend to further build out this platform to allow enterprise users build their own Energy Asset & Risk Management Workflows.",
        },
    ]

    return (
        <>
            <div className="landingFeatures" id="landing-features">
                <div className="landingSolution-container container">
                    <div className="row ">

                        <div className="col-lg-6 landingFeatures-content ">
                            <img src={img1}  alt="demo example" className="solution-demo-image"/>
                        </div>

                        <div className="col-lg-6 p-3 ml-2">
                            <h3 className='common-subheader2'>For operations</h3>
                            <h2 className="common-header">How it works</h2>
                            {featuresData.map((val,ind)=>{
                            return(
                                <div className="landingFeatures-text " key={ind} onClick={()=>{setIndex(ind);}}>
                                    <div>
                                        <h2 className={ind===index?"highlighted-text common-subheader":"landingFeatures-inactive-header"}><span className={ind===index?"active-sign":"inactive-sign"}>&gt;</span> {val.heading}</h2>
                                        <p className={ind===index?"common-description display":"hidden"}>{val.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                        
                        
                        </div>

                    </div>

                    


                </div>
            </div>
        </>
    )
}

export default LandingFeatures
