import React from 'react'
import {Button} from '../../Button'
import {Link as LinkS} from 'react-scroll'
import './landingScreen.css'

function index() {
    return (
        <>
            <div className="landingScreen" id="home">
                <div className="landingScreen-container container">
                    <h1 className="landingScreen-header">How will you combat climate change?</h1>
                    <p className="landingScreen-subheader mb-4">Our platform helps you gear up for the challenge.</p>
                    <LinkS to="landing-solution" smooth={true}><Button> Try it Now </Button></LinkS>
                </div>
            </div>
        </>
    )
}

export default index
