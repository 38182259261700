import React from 'react'
import img1 from '../../../images/LandingPage/Rectangle.png'
import './landingAbout.css'

function index() {
    return (
        <>
            <div className="landingAbout" id="landing-about">
                <div className="landingAbout-container container">
                    <div className="row">
                        <div className="col-lg-6 landingAbout-text">
                            <h3 className='common-subheader2'>About Us</h3>
                            <h2 className="common-header">What drives us</h2>
                            <p style={{fontWeight:"800"}}>A Peak into our motivation</p>
                            <p className="common-description">The energy transition is a pathway toward transformation of the global energy sector from fossil-based to zero-carbon by the second half of this century. But this comes at a risk since there is no real-time intelligence tool available today to manage climate risk while still benifiting from the alternative energy sources such as solar, wind and water.
                            <br/><br/> GreenScan is developing an all-encompassing climate intelligence platform that can be integrated into all decisions by corporations, government and individuals. GreenScan intends to baseline, monitor, and forecast risk across all climate domains such that organizations can take appropriate action to safeguard their assets and transition toward a more sustainable future.
                            </p>
                            
                        </div>

                        <div className="col-lg-6 landingAbout-image">
                            <img src={img1} alt="feature"  style={{width:"100%"}}/>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default index
