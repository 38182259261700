import React from 'react'
import Hero from '../components/Software/Hero'
import Pricing from '../components/Software/Pricing'
import Assets from '../components/Software/Assets'
import LandingCA2 from '../components/Landing Page/Landing CA2'

function Software() {
  return (
    <>
    <Hero />
    <Assets />
    <Pricing />
    <LandingCA2 />
    </>
  )
}

export default Software