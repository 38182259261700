import React,{useRef,useState} from 'react'
import axios from 'axios'
// import {Link as LinkS} from 'react-scroll'
import img1 from '../../../images/demoImages/cell.jpg'
import img2 from '../../../images/demoImages/cracking.jpg'
import img3 from '../../../images/demoImages/diode.jpg'
import img4 from '../../../images/demoImages/diode-multi.jpg'
import img5 from '../../../images/demoImages/hotspot-multi.jpg'
import img6 from '../../../images/demoImages/shadowing.jpg'
import img7 from '../../../images/demoImages/offline-module.jpg'
import './juaDemo.css'

function JuaDemo() {

    const DemoOptions=[
        //   {
        //       name:"Select",
        //       image:img1,
        //       value:0
    
        //   },
          {
              name:"Cell",
              image:img1,
              value:1
          },
          {
            name:"Cracking",
            image:img2,
            value:2
          },
          {
            name:"Diode",
            image:img3,
            value:3
          },
          {
            name:"Diode-Multi",
            image:img4,
            value:4
        },
          {
            name:"Hot-Spot Multi",
            image:img5,
            value:5
        },
        {
            name:"Shadowing",
            image:img6,
            value:6
        },
        {
            name:"Offline-module",
            image:img7,
            value:7
        },
      ]

      const [image,setImage] = useState(null);
      const [result, setResult] = useState(null);
      const [loading, setLoading] = useState(false);

      const hiddenFileInput = useRef(null);
  
      const handleClick = () => {
        hiddenFileInput.current.click();
      };

      const handleChange = event =>{

          const fileUploaded = event.target.files[0];
          console.log(fileUploaded);
          setImage(URL.createObjectURL(fileUploaded));
          postImage(fileUploaded);
      }

      const postImage = async (imageData) =>{
        setLoading(true);
        setResult(null);
        const formData = new FormData();
        formData.append('file', imageData)
        let url='https://1ei5j2kto9.execute-api.us-east-1.amazonaws.com/prod/predict/';
        let res =await axios.post(url,formData);
        let result2 = res.data;
        setResult(result2);
        setLoading(false);
    } 


    const onImageChange = async (e) => {
        setResult(null);
        setLoading(true);
        if(e.target.value === "Select Image"){
            setImage(null);
            setLoading(false);
            return;
        }
        setImage(DemoOptions[e.target.value-1].image)
        const response = await fetch(DemoOptions[e.target.value-1].image)
        const blob =await response.blob()
        const file = new File([blob], 'dot.jpg', {type:'image/jpg'})

        const formData = new FormData();
        formData.append('file',file)
        let url='https://1ei5j2kto9.execute-api.us-east-1.amazonaws.com/prod/predict/';
        let res = await axios.post(url,formData);
        let result2 = res.data;
        setResult(result2);
        setLoading(false);
    }


      function renderImage(){
        if(image){
            return (
                <img src={image} style={{height:"150px"}} alt="Uploaded file"/>
            )
        }
        else{
            return(
                <p className="common-description text-center">Please upload infrared image of solar PV.</p>
            )
        }
    }

    function renderResult(){
        if(result){
            let keys=Object.keys(result.prediction)
            // const values=result.prediction
            const array = [0,1,2,3,4]
            function getWidth(value){
                return (value/result.prediction[keys[0]])*70;
            }

            return(
                array.map((val,ind) =>{
                    return(
                        <div className="jua-result-values" style={{color:ind===0? "#80E400" : "black", fontWeight:ind===0? "800" : "500"}} key={val}>
                            <div className="row" style={{width:"100%"}}>
                                <div className="col-6">
                                    <p className="Result">{keys[val]}</p>
                                </div>
                                <div className="col-4">                
                                    <div style={{width:getWidth(result.prediction[keys[val]]), height:"10px", background:ind===0? "#80E400" : "black", marginTop:"5px"}}>                        </div>
                                </div>
                                <div className="col-2">
                                    <p>{Math.round(result.prediction[keys[val]])}%</p>
                                </div>
                            </div>
                        </div>
                        
                    )
                }
                )
                
            )
        }

        else{
            const array = [0,1,2,3,4]

            if(!loading){
                return(
                    array.map((val,ind) =>{
                        return(
                            <div className="jua-result-values" style={{color:ind===0? "#80E400" : "black", fontWeight:ind===0? "800" : "500"}} key={val}>
                                <div className="row" style={{width:"100%"}}>
                                    <div className="col-6">
                                        <p className="Result">Prediction</p>
                                    </div>
                                    <div className="col-4">                
                                        <div style={{width:"20px", height:"10px", background:ind===0? "#80E400" : "black", marginTop:"5px"}}>                        </div>
                                    </div>
                                    <div className="col-2">
                                        <p>0%</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                )
            }

            else{
                return(
                    <div className="spinner-container">
                        <div className="spinner-border loading-spinner" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                )
            }
            
        }
    }
    


    


    return (
        <>
            <div className="jua-demo" id="jua-demo">
                <div className="jua-demo-container container">
                    <div className="jua-demo-header text-center">
                        <h2 className="common-header">GS Solar platform Demo</h2>
                        <p className="common-subheader mb-5">Select one of the images or upload from your device to see the results.</p>
                    </div>


                    <div className="jua-demo-component">
                        <div className="jua-demo-component-inner">
                            <div className="row">
                                <div className="col-lg-4 jua-demo-options text-center">
                                    <div className="jua-demo-options-select">
                                        <select className="form-select mb-4" aria-label="Default select example" onChange={onImageChange}>
                                            <option className="options-image" selected>Select Image</option>
                                            {
                                                DemoOptions.map((val,index)=>{
                                                    return(
                                                        <option className="options-image" value={val.value} key={index} image={val.image}
                                                            
                                                        >{val.name}</option>
                                                    )
                                                })
                                            }

                                        </select>

                                        <p className="subheader2">OR</p>
                                        
                                        <button className="btn btn-primary jua-demo-button" onClick={handleClick}>Upload an image</button>
                                        <input 
                                            type="file"
                                            name="file"
                                            ref={hiddenFileInput}
                                            onChange={handleChange}
                                            style={{display:'none'}}
                                        />
                                    </div>

                                        {/* <p className="common-description jua-demo-description mt-5" >
                                            <span style={{fontWeight:"bold"}}>Result Description:</span><br/>
                                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed qui magni dolores eo qui ratione voluptatem sequi nesciunt. Neque porr quisquam est, qui dolorem.Neque porro quisquam est,qui dolorem.
                                        </p> */}
 
                                </div>



                                <div className="col-lg-8 jua-demo-canvas p-1">
                                        <div className="jua-demo-canvas-inner">
                                            <div className="text-center">
                                                <p>Predictions</p>
                                            </div>

                                            <div className="row jua-demo-canvas-results">
                                                <div className="col-md-6 jua-demo-image">
                                                    <div>
                                                        {renderImage()}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 jua-demo-result">
                                                    <div className="jua-demo-result-inner">
                                                        {renderResult()}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </>
    )
}

export default JuaDemo
