import React from 'react'
import {Button} from '../../Button'
import './tufanLanding.css'
import {Link as LinkS} from 'react-scroll'

function index() {
    return (
        <div className="tufan-landing">
            <div className="tufan-landing-container container">
                <div className="tufan-landing-header text-center">
                    <h1 className="common-header">GS Wind Demo</h1>
                    <p className="common-subheader">Experience it yourself</p>
                    <LinkS to="tufan-demo" smooth={true}><Button> Try it Now </Button></LinkS>
                </div>
            </div>
        </div>
    )
}

export default index
