import React,{useState, useEffect} from 'react'
import {Link} from 'react-scroll'
import {Link as LinkL} from 'react-router-dom'
import { Button } from '../Button';
import img1 from '../../images/global/Logo_GreenScan-05.svg'
import ico1 from '../../images/icons/bars.png'
import ico2 from '../../images/icons/close.png'
import './navbar.css'

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () =>{
        if(window.innerWidth <=960){
            setButton(false);
        }
        else{
            setButton(true);
        }
    }

    useEffect(() =>{
        showButton()
    },[])

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar-navbar">
                
                <div className="navbar-container">
                    <LinkL to="/" className="navbar-logo" onClick={closeMobileMenu} style={{width:"200px"}}>
                        <img src={img1} className="navbar-logo-image" alt="Rezlytix Logo"></img>
                    </LinkL>
                    <div className="menu-icon" onClick={handleClick}>
                        {/* <i className={click ? 'fas fa-times': 'fas fa-bars'}/> */}
                        <img className='menu-buttons' src={click? ico2: ico1} alt="menu-button" />
                    </div>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <LinkL to="/" spy={true} smooth={true} onClick={closeMobileMenu} className='nav-links' >
                                Home
                            </LinkL>
                        </li>
                        <li>
                            <Link to="landing-about" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMobileMenu} className='nav-links' >
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="landing-solution" spy={true} smooth={true} offset={-50} duration={500}  onClick={closeMobileMenu} className='nav-links'>
                                    Products
                            </Link>
                        </li>
                        <li>
                            <Link  to="landing-features" spy={true} smooth={true} offset={-50} duration={500}  onClick={closeMobileMenu} className='nav-links' >
                                    Features
                            </Link>
                        </li>
                        <li>
                            <Link to="jua-demo" spy={true} smooth={true} duration={500}  onClick={closeMobileMenu} className='nav-links' >
                                    Demo
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="pricing" spy={true} smooth={true} duration={500}  onClick={closeMobileMenu} className='nav-links' >
                                    Pricing
                            </Link>
                        </li> */}
                        <li>
                            <Link to="landing-team" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMobileMenu} className='nav-links' >
                                    Team
                            </Link>
                        </li>
                        <li>
                            <LinkL to='/form' className='nav-links-mobile' onClick = {closeMobileMenu} >
                                Demo
                            </LinkL>
                        </li>
                    </ul>

                    {button && <LinkL to="/form"><Button buttonStyle="btn--primary">Demo</Button></LinkL>}
                </div>

            </nav>
        </>
    )
}

export default Navbar