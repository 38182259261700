import React from 'react'
import './landingCA1.css'

function index() {
    return (
        <>
            <div className="landingCA1">
                <div className="landingCA1-container container text-center">
                    <h2 className="common-header">Solve Climate problems, with GreenScan's Demo</h2>
                    <p style={{fontWeight:"800"}}>The only solution to blend big data, proprietary technology, machine learning, and more.</p>
                </div>
            </div>
        </>
    )
}

export default index
