import React from 'react'
import img1 from '../../../images/Software/f1.jpg'
import img2 from '../../../images/Software/f2.jpg'
import img3 from '../../../images/Software/f3.jpg'
import img4 from '../../../images/About/image1.jpg'
import './assets.css'

function index() {

    const data=[
        {
            heading:"#Feature1",
            desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at.",
            img:img1
        },
        {
            heading:"#Feature2",
            desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at.",
            img:img2
        },
        {
            heading:"#Feature3",
            desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at.",
            img:img3
        }
    ]

  return (
    <div className='assets-container'>
        <div className='container'>
            <h2 className='common-header-2'>We offer the best climate solution.</h2>
            <p className='common-description assets-desc mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at. Quisque varius egestas erat ut consequat. Morbi vulputate maximus turpis non dignissim. Morbi ut est arcu. Fusce convallis commodo massa sed posuere.</p>
            <div className='row'>
                {data.map((val,ind)=>{
                    return(
                        <div className='col-md-4 p-4' key={ind}>
                            <img src={val.img} className="mb-3"></img>
                            <h2 className='assets-header mb-3'>{val.heading}</h2>
                            <p className='common-description mb-3'>{val.desc}</p>
                        </div>
                    )
                })}
            </div>

            <div className='assets-features py-5'>
                <div className='row'>
                    <div className='col-lg-8 p-4'>
                        <img src={img4} style={{maxWidth:"100%", borderRadius:"10px"}}></img>
                    </div>
                    <div className='col-lg-4 p-4'>
                        <h2 className='common-header-2'>A wide range of climate services</h2>
                        <p className='common-description' style={{fontSize:"18px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at. <br/><br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ex lorem, ac accumsan odio semper at. </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default index