import React from 'react'
import emailjs from 'emailjs-com'
// import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import './form.css'


function Craform() {
    const history = useHistory();
    // const[isLoading,setLoading] = useState(false);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const MyCheckbox = ({ children, ...props }) => {
      const [field, meta] = useField({ ...props, type: 'checkbox' });
      return (
        <div>
          <label className="checkbox-input">
            <input type="checkbox" {...field} {...props} />
            {children}
          </label>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };

    function SendEmail(object) {
      document.getElementById("submit-button").disabled = true;
      emailjs.send("service_ti7r6pf", "template_42ueekc", object,"user_LPYbBbJHwnWcpKuEIZFoc" )
      .then(console.log("submitting"))
          .then((result) => {
              history.push('/formOk')
              // setLoading(true);
              console.log(result.text)
          }, (error) => {
              console.log(error.text)
          })
  }

    return (
        <>
            <div className="form-component">
                <div className="form-container container-fluid">
                    <div className="row">
                        <div className="col-lg-6 form-header-container d-flex align-items-center">
                            <div className="form-header-outer">
                                <h2 className="form-header pt-2 pb-4">Get a free demo of the GreenScan platform.</h2>
                            </div>
                        </div>

                        <div className="col-lg-6 form-values-container">
                          <div className="form-container-form">
                          <Formik
                            initialValues={{ firstName: '', lastName: '', email: '', company:'', phone:'', industry:'', employeecount:'',acceptedTerms: false,additionalComments:''}}
                            validationSchema={Yup.object({
                              firstName: Yup.string()
                                .max(15, 'Must be 15 characters or less')
                                .required('Required'),
                              lastName: Yup.string()
                                .max(20, 'Must be 20 characters or less')
                                .required('Required'),
                              email: Yup.string().email('Invalid email address').required('Required'),
                              company: Yup.string()
                                .required('Required'),
                              phone:Yup.string().matches(phoneRegExp,'Phone number is not valid'),
                              acceptedTerms: Yup.boolean()
                              .required('Required')
                              .oneOf([true], 'You must accept the terms and conditions.'),
                            })}
                            onSubmit={(values, actions) => {
                              setTimeout(() => {
                                SendEmail(values)
                                actions.setSubmitting(false)
                              }, 1000)
                            }}                     
                          >
                            <Form>
                              <div className="row">
                                <div className="col-md-6 mb-4">
                                  <Field name="firstName"  placeholder="First Name*"/>
                                  <ErrorMessage name="firstName" >
                                  { msg => <div style={{ position:'absolute',color: 'red', fontSize:"small"}}>{msg}</div> }
                                  </ErrorMessage>
                                </div>
                                <div className="col-md-6 mb-4">
                                  <Field name="lastName" type="text" placeholder="Last Name*"/>
                                  <ErrorMessage name="lastName" >
                                  { msg => <div style={{position:'absolute', color: 'red', fontSize:"small"}}>{msg}</div> }
                                  </ErrorMessage>
                                </div>
                                <div className="col-md-6 mb-4">
                                  <Field name="email" type="email" placeholder="Business Email*"/>
                                  <ErrorMessage name="email" >
                                  { msg => <div style={{position:'absolute', color: 'red', fontSize:"small"}}>{msg}</div> }
                                  </ErrorMessage>
                                </div>
                              </div>
                              
                              <div className="row">
                                <div className="col-md-6 mb-4">
                                  <Field name="company" type="text" placeholder="Company Name*"/>
                                  <ErrorMessage name="company" >
                                  { msg => <div style={{position:'absolute', color: 'red', fontSize:"small"}}>{msg}</div> }
                                  </ErrorMessage>
                                </div>
                                <div className="col-md-6 mb-4">
                                  <Field name="phone" type="number" placeholder="Phone Number(Optional)"/>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <Field name="industry" type="text" placeholder="Industry(Optional)"/>
                                  <ErrorMessage name="industry" /> 
                                </div>
                                <div className="col-md-6 mb-3">
                                  <Field name="employeecount" type="text" placeholder="Number of Employees(Optional)"/>
                                  <ErrorMessage name="employeecount" /> 
                                </div>
                                <div className="col-12 mb-3">
                                  <Field name="additionalComments" component="textarea" style={{width:"100%", resize:"none"}} rows="4" type="text" placeholder="Additional Comments (Optional)"/>
                                </div>
                              </div>

                              <MyCheckbox name="acceptedTerms">
                                <span> I agree to GreenScan's Terms of Service and privacy policy.</span>
                              </MyCheckbox>
                              <button className='btn btn-primary submit-btn' id='submit-button' type="submit">Submit</button>
                            </Form>
                          </Formik>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Craform
