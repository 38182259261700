import React,{useState} from 'react'
import img1 from '../../../images/LandingPage/tarry.png'
import img2 from '../../../images/LandingPage/tannistha.png'
import img7 from '../../../images/LandingPage/foke.jfif'
import img3 from '../../../images/LandingPage/rohit.jfif'
import img4 from '../../../images/LandingPage/Shubham.png'
import img5 from '../../../images/LandingPage/narendra.png'
import img6 from '../../../images/LandingPage/hammad.jfif'
import {AiFillLinkedin} from 'react-icons/ai'
import './landingTeam.css'

function LandingTeam() {

    const data = [
        {
          name:"Tarry Singh",
          image:img1,
          position:"CEO",
          description:"Tarry is a seasoned tech entrepreneur, philanthropist and AI researcher having setup multiple startups in the past. He has over 25 years experience helping enterprises build and scale disruptive technologies to gain competitive edge.",
          linkedIn:"https://www.linkedin.com/in/tarrysingh/",
          state:1
        },
        {
          name:"Tannishta Maiti",
          image:img2,
          position:"CTO/Head of Engineering",
          description:"Dr. Tannistha Maiti is a PhD Geophysics and Seismology from University of Calgary and MS from IIT Kharaghpur in Exploration Geophysics. Tannistha heads engineering and is founding board member for deepkapha.ai Toronto, Canada.",
          linkedIn:"https://www.linkedin.com/in/tannisthamaiti/",
          state:2
        },
        {
            name:"Ms. Foke CD Helm",
            image:img7,
            position:"COO",
            description:"Dr. Tannistha Maiti is a PhD Geophysics and Seismology from University of Calgary and MS from IIT Kharaghpur in Exploration Geophysics. Tannistha heads engineering and is founding board member for deepkapha.ai Toronto, Canada.",
            linkedIn:"https://www.linkedin.com/in/foke-singh-van-der-helm-457bb230/",
            state:3
          },
        {
          name:"Rohit Mishra",
          image:img3,
          position:"Head of Product",
          description:"Rohit is a data scientist in Renewable Energy domain and works in building predictive models for the unpredictable (wind power). He help to bridge the gap between Industrial Asset Performance and Customer Expectation by providing key insights and analytical solution.",
          linkedIn:"https://www.linkedin.com/in/rohitmishra1484/",
          state:4
        },
        {
            name:"Narendra Patwardhan",
            image:img5,
            position:"Head AI Researcher",
            description:"Rohit is a data scientist in Renewable Energy domain and works in building predictive models for the unpredictable (wind power). He help to bridge the gap between Industrial Asset Performance and Customer Expectation by providing key insights and analytical solution.",
            linkedIn:"https://www.linkedin.com/in/narendrapatwardhan/",
            state:5
          },
        {
          name:"Shubham Gandhi",
          image:img4,
          position:"Sr. ML Engineer",
          description:"Shubham is a graduate from Heriot-Watt University in MSc Artificial Intelligence. He is passionate about working on ideas of using AI to fight global climate change and in different applications of using AI social good. He adheres to the principle of life long learning and is mainly interested in models that affect and impact the real world.",
          linkedIn:"https://www.linkedin.com/in/dudeperf3ct/",
          state:6
        },
        {
            name:"Hammad Basit",
            image:img6,
            position:"ML Engineer/SDE",
            description:"Shubham is a graduate from Heriot-Watt University in MSc Artificial Intelligence. He is passionate about working on ideas of using AI to fight global climate change and in different applications of using AI social good. He adheres to the principle of life long learning and is mainly interested in models that affect and impact the real world.",
            linkedIn:"https://www.linkedin.com/in/hammad-basit-a7895a17a/",
            state:7
          },
      ]


    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="landingTeam py-5" id='landing-team'>
                <div className="landingTeam-container container">
                    <div className="landingTeam-header pt-5 pb-5 text-center">
                        <h2 className="common-header">You're in a good company</h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p> */}
                    </div>
                </div>
                
                <div className="team-tabs">
                    <div className="container">
                        <div className="row mb-5">
                            {data.map((val,ind)=>{
                                return(
                                    <div className="col-lg-3 col-md-4 mb-4 team-content text-center" key={ind}>
                                        <div className="team-members" onClick={() => toggleTab(ind)}>
                                            <img src={val.image} alt="team-member" className='mb-4 team-member-image' style={{borderRadius:"50%", height:"200px"}}></img>
                                            <h2 className="team-member-name-1">{val.name}</h2>
                                            <p className="common-description">{val.position}</p>
                                            {/* <div className="small-screen">
                                                <p className={toggleState === ind ? "active-description team-member-description":"team-member-description"}>{val.description}</p>
                                            </div> */}
                                            <a href={val.linkedIn} target="_blank" rel="noreferrer"><AiFillLinkedin size="20px" /></a>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {/* <div className="selected-team-member large-screen row">
                            <div className='col-md-4 flex text-center'>
                                <img src={data[toggleState].image} alt="team-member"  style={{height:"100%", paddingRight:"20px", borderRadius:"50%", maxHeight:"250px" }}></img>
                            </div>
                            
                            <div className="selected-team-member-text col-md-8">
                                <h2 className="team-member-name">{data[toggleState].name}</h2>
                                <p className="common-description">{data[toggleState].position}</p>
                                <p>{data[toggleState].description}</p>
                                <a href={data[toggleState].linkedIn}><i className="fab fa-linkedin fa-lg"></i></a>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingTeam
