import React from 'react'
import Form from '../components/Form'

function DemoForm() {
    return (
        <>
            <Form />
        </>
    )
}

export default DemoForm
